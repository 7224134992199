import React, { useState, useEffect, useRef } from "react"
import Layout from '../components/global/layout.js'
import indexStyles from '../style/index.module.scss'
import { FormattedMessage } from 'gatsby-plugin-intl'
import ContentSection from "../components/global/contentsection.js"
import { Helmet } from "react-helmet"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Video360 from "../img/index.video/elephant-360.mp4"
import Video480 from "../img/index.video/elephant-480.mp4"
import Video720 from "../img/index.video/elephant-720.mp4"
import Video1080 from "../img/index.video/elephant-1080.mp4"
import Video360WM from "../img/index.video/elephant-360.webm"
import Video480WM from "../img/index.video/elephant-480.webm"
import Video720WM from "../img/index.video/elephant-720.webm"
import Video1080WM from "../img/index.video/elephant-1080.webm"
import VideoPoster from "../img/index.video/elephant-1080.jpg"
import styled from "styled-components"
import Image from "../components/global/image.js"
import CallToAction from '../components/global/calltoaction.js'

import Brackets from '../img/3d/brackets.png'
import SpeechBubble from '../img/3d/speechbubble.png'
import Server from '../img/3d/server.png'

import { useInView } from "react-intersection-observer"


const FloatImage = styled.div`
position: absolute;
transform: scale(1.2) translate(50%, -30%);
`


const IndexPage = () => {

  const [refVidContainer, inView] = useInView({ threshold: 0 })

  let [scrollState, setScrollState] = useState(1)
  let [scrollPos, setScrollPos] = useState(0)
  let [windowWidth, setWindowWidth] = useState(0)
  let [fixedScrollContent, setFixedScrollContent] = useState(false)
  let [strikeWidth, setStrikeWidth] = useState(0)
  let [endContentPosition, setEndContentPosition] = useState(0)
  const refDiv = useRef(0)
  const refVid = useRef(0)

  useEffect(() => {
    if (inView) {
      refVid.current.play()
    }
    else {
      refVid.current.pause()
    }
  }, [refVidContainer, inView])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    window.addEventListener("onload", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
      window.removeEventListener("onload", handleScroll)
    }
  })

  useEffect(() => {
    window.addEventListener("resize", setWindowWidth(window.innerWidth))
    return () => {
      window.removeEventListener("resize", setWindowWidth(window.innerWidth))
    }
  }, [windowWidth])


  function getVideoSize() {
    if (windowWidth > 1080) {
      return Video1080
    }
    if (windowWidth > 720) {
      return Video720
    }
    if (windowWidth > 480) {
      return Video480
    }
    else {
      return Video360
    }
  }
  function getVideoSizeWM() {
    if (windowWidth > 1080) {
      return Video1080WM
    }
    if (windowWidth > 720) {
      return Video720WM
    }
    if (windowWidth > 480) {
      return Video480WM
    }
    else {
      return Video360WM
    }
  }


  function handleScroll() {
    let scrollDiff = window.pageYOffset - refDiv.current.offsetTop;
    let divSize = refDiv.current.offsetHeight

    const noOfSlides = 5
    const slideSize = (divSize - window.innerHeight) / noOfSlides
    let scrollPercentage = scrollDiff / slideSize * 100
    setScrollPos(scrollPercentage)


    if (window.pageYOffset >= refDiv.current.offsetTop) {
      setFixedScrollContent(true)
      if (scrollDiff >= slideSize * 3) {
        setScrollState(4)
        let sWidth = ((scrollDiff - (slideSize * 3)) / slideSize * 100)
        let endContPos = (100 - (scrollDiff - (slideSize * 3)) / slideSize * 100)
        sWidth < 100 ? setStrikeWidth(sWidth) : setStrikeWidth(100)
        endContPos > 0 ? setEndContentPosition(endContPos) : setEndContentPosition(0)
      }
      else if (scrollDiff >= slideSize * 2) { setScrollState(3) }
      else if (scrollDiff >= slideSize) { setScrollState(2) }
      else if (scrollDiff >= 0) { setScrollState(1) }
    } else {
      setFixedScrollContent(false)
    }

    if (scrollState < 4) {
      setEndContentPosition(100)
    }
  }


  function setScrollContentPos() {
    return fixedScrollContent ? { position: "fixed" } : { position: "sticky" }
  }

  function setOffset(scrollStateNo) {
    if (scrollState < scrollStateNo) {
      return { overflow: "hidden", height: "0px", transform: "translateX(100%)" }

    } else if (scrollState === scrollStateNo) {
      return { display: "flex", transform: "translateX(0%)" }
    }
    else if (scrollState > scrollStateNo) {
      return { overflow: "hidden", height: "0px", transform: "translateX(-100%)" }
    }
  }


  return (
    <Layout>

      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Spherical Elephant | Individuelle Softwarelösungen</title>
        <meta name="description" content="Software | Websites | Apps | Infrastruktur | Cloud | Digital Design | Consulting & Schulungen -  Full Service IT Agentur in 1090 Wien" />
      </Helmet>

      <section ref={refVidContainer} className={indexStyles.videoSection}>
        <video ref={refVid} poster={VideoPoster} autoPlay="autoplay" preload="preload" playsInline="playsinline" muted loop className={indexStyles.video}>
          <source src={getVideoSize()} type="video/mp4" />
          <source src={getVideoSizeWM()} type="video/webm" />
          Your browser does not support video
        </video>
        <div data-sal="slide-right"
          data-sal-delay="200"
          data-sal-duration="600"
          data-sal-easing="ease"
          className={indexStyles.videoTitle}>
          <p className={indexStyles.title}><FormattedMessage id="Home.title" /></p>
          <CallToAction mode="dark" />
        </div>
      </section>

      <ContentSection>
        <section className={indexStyles.scrollContainer} ref={refDiv}>
          <div className={indexStyles.scrollBox} style={setScrollContentPos()}>
            <div data-sal="slide-right"
              data-sal-delay="2000"
              data-sal-duration="700"
              data-sal-easing="ease"
              className={indexStyles.centered}>
              <h3 className={indexStyles.stickyContent}><FormattedMessage id="Home.weDo.h" /></h3>
              <FloatImage style={{ right: scrollPos + "%" }}> <Image className={indexStyles.browserIcon} src={Brackets} alt="Services.design.img.1" /></FloatImage>
              <FloatImage style={{ right: scrollPos - 100 + "%" }}><Image className={indexStyles.bracketsIcon} src={SpeechBubble} alt="Services.development.img.1" /></FloatImage>
              <FloatImage style={{ right: scrollPos - 200 + "%" }}><Image className={indexStyles.browserIcon} src={Server} alt="Services.development.img.3" /></FloatImage>
              <div className={indexStyles.slide} style={setOffset(1)}><FormattedMessage id="Home.weDo.software" /></div>
              <div className={indexStyles.slide} style={setOffset(2)}><FormattedMessage id="Home.weDo.analysis" /></div>
              <div className={indexStyles.slide} style={setOffset(3)}><FormattedMessage id="Home.weDo.supportMaintenanceOperations" /></div>
              <div className={indexStyles.slide} style={setOffset(4)}>
                <div className={indexStyles.spacestations}> <div style={{ width: strikeWidth + "%" }} /><FormattedMessage id="Home.weDo.spacestations" /></div>
              </div>
              <div className={indexStyles.endContent} style={{ transform: "translateX(" + endContentPosition + "%)" }}>
                <span style={{ display: "inline-block", opacity: ".4" }}><FormattedMessage id="Home.weDo.almost" /></span> <FormattedMessage id="Home.weDo.everything" /></div>
            </div>
            <AniLink className={indexStyles.link} cover bg="#F2F2F2" duration={1} direction="right" to="../services">
              <p><FormattedMessage id="Nav.services" /></p>
            </AniLink>

          </div>
        </section>

      </ContentSection>

    </Layout>

  )
}

export default IndexPage
